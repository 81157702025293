.calendar-page {
    display: block;
    width: auto;
    height: auto;
}

/* ---------------------- main ---------------------- */

.calendar-page main {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212121;
}
