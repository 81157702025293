.contact-page {
    display: block;
    width: auto;
    height: 100%;
}

/* ---------------------- main ---------------------- */

.contact-page main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #212121;
    height: 100vh;
}

.contact-page main .grid {
    display: flex;
    flex-direction: row;
    
}

.contact-page main .grid .social {
    margin: 8% 30px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 

.social .pseudo {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px;
}

.social img {
    margin-bottom: 5%;
}


.social .follow {
    padding: 10px;
    cursor: pointer;
    border: 2px solid yellow; 
    color: yellow; 
    background: transparent; 
    transition: background 0.3s, color 0.3s; 
    font-size: 18px;
}

.carte {
    width: 60%;
    height: 100%;
    border: 2px solid #FFEB3B;
    margin: 10px;
}

.endtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vh;
    height: auto;
    background-color: #212121;
    color: white;
    padding: 10px;
}

/* ---------- Iphones ---------- */

@media only screen and (max-width: 600px) {
    .contact-page main {
        height: auto;
    }

    .contact-page main .grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-page main .grid .social {
        height: auto;
    }

    .contact-page main .grid .social .pseudo {
        height : auto;
        overflow: hidden;
        margin: 0;
    }

    .contact-page main .grid .social img {
        margin-top: 10%;
    }

    .contact-page main .carte {
        min-height: 100px;
        min-width: 300px;
        width: auto;
        margin: 0;
    }

    .contact-page main .endtext {
        width: auto;
    }
}