.calendar {
  height: auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.rbc-calendar {
  font-size: 18px;
  min-width: 90%;
  height : auto;
}

/* ---------- ToolBar ---------- */

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
  color: white;
  min-width: 15vh;
  width: auto;
  min-height: 8vh;
  height: 100%;
  font-size: 24px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button:hover {
  background-color: white;
  color: black;
  transition: 0.3s;
}

.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
  font-size: 28px;
}

/* background color of cells */
.rbc-day-bg {
  background-color: white; /* Changez cette couleur en fonction de vos besoins */
}

/* Taille de chaque cellule */
.rbc-month-row, .rbc-week-row {
  min-height: 120px; /* Cette valeur peut être ajustée en fonction de vos besoins */
}

.rbc-row-segment {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Texte à l'intérieur des cellules */
.rbc-event {
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: black;
  color: white;
  border: none !important;
  padding: 2px;
  height: 100%;
  width: 80%;
  font-size: 22px;
}

/* Month and Year */
.rbc-toolbar-label {
  color: white;
}

/* Day names */
.rbc-header {
  color: white;
}

/* day numbers */
.rbc-date-cell {
  width: 14.2%; /* 100/7 = 14.2 */
  font-size: 30px;
  color: black; /* Changez cette couleur en fonction de vos besoins */
}

/* today's cell */
.rbc-today {
  background-color: yellow;
}

.rbc-agenda-view {
  background-color: white;
}

.rbc-agenda-view .rbc-header {
  color:black;
}

.rbc-time-view {
  background-color: white;
}

.rbc-time-view .rbc-time-header {
  background-color: black;
}

.rbc-button-link { 
  background-color: white !important;
}

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width: 600px) {
  .rbc-calendar {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* ---------- ToolBar ---------- */

  .rbc-calendar .rbc-toolbar {
    width : 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

  .rbc-calendar .rbc-toolbar .rbc-btn-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .rbc-calendar .rbc-toolbar .rbc-btn-group button {
    height : auto;
    width: auto;
    font-size: 3vw;
  }

  .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    padding : 15px 5px;
  }

  .rbc-date-cell {
    min-width: auto;
  }

  .rbc-calendar .rbc-month-view {
    height: auto;
    overflow: visible;
  }

  .rbc-event {
    background-color: transparent;
    color: transparent;
    width: 100%;
  }
}