.login-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #030303;
}

.login-page .side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 80vh;
}

.login-page .side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mirror {
  transform: scaleX(-1);
}

/* ------------ formulaire ------------ */

.login-container {
  margin: 0 auto;
  width: auto;
  min-width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: visible;
  z-index: 1000;
}

.login-container .link-logo {
  margin-bottom: 10vh;
  position: relative;
  height: 150px;
  min-height: 15vh;
  width: 150px;
  min-width: 15vh;
  overflow: hidden;
}

.login-container .link-logo .logo {
  height: 100%;
  width: auto;
}
  
.login-container form {
  padding: auto;
  width: auto;
  min-width: 20vw;
  height: auto;
  border: 2px solid #FFEB3B;
  display: flex;
  flex-direction: column;
}
  
.login-container label {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.login-container input {
  font-size: 16px;
  margin-bottom: 25px;
  padding: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px;
  cursor: pointer;
  border: 2px solid yellow; 
  color: yellow; 
  background: transparent; 
  transition: background 0.3s, color 0.3s; 
  font-size: 18px;
}

.submit:hover {
  color: black; 
  background: yellow; 
}
  
.forget-password {
  margin-top: 20px;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

/* -------------------------------------------- */
/* ------------------ Iphone ------------------ */
/* -------------------------------------------- */

@media only screen and (max-width: 600px) {
  .login-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .login-page .side {
    width: 0;
    height: 0;
  }

  .login-container {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .login-container form {
    padding: 10%;
  }

}