.club-page {
    height: auto;
    overflow: hidden;
    max-height: 100vh;
}

.club-page main {
    display: block;
    width: auto;
    height: auto;
    min-height: 100vh;
    background-color: #212121;
}
/* ---------------------- main ---------------------- */

.club-page main .grid {
    height : 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding: 5% 3%;
    background-color: #212121;
}

.club-page main .grid .club {
    position: relative;
    padding : 0px 2%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.club-page main .grid .club h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    padding-bottom: 2%;
}

.club-page main .grid .club .link {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.club-page main .grid .club .link .logo {
    width: auto;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.club-page main .grid .club .link .logo:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 600px){

    .club-page {
        max-height: 666vh;
    }

    .club-page main .grid {
        height: auto;
        grid-template-columns: 1fr;
    }

    .club-page main .grid .club {
        height: auto;
        padding: 0;
        margin-bottom: 50px;
        border-bottom: 1.5px solid lightgray;
    }
}