.home-page {
    display: block;
    width: 100%;
    height: auto;
    overflow: visible;
}

/* main */

.home-page main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212121;
}

.home-page main .slogan {
    position: relative;
    margin: 30px 0px;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 42px;
    font-family: 'AllStar';
    text-align: center;
}

.home-page main .banniere {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding-bottom: 5%;
    border-bottom: 5px solid white; 
}

.home-page main .banniere img { /* format image */
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}

.home-page main .actualite {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    border-bottom: 5px solid white;
}

.home-page main .actualite h1 {
    color: white;
    font-size: 55px;
    font-family: 'GTA';
    color: #D49303;
}

.home-page main .presentation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 3% 5%;
}

.home-page main .presentation h1 {
    font-size: 50px;
    padding: 5%;
    text-align: center;
    font-family: 'AllStar';
}

.home-page main .presentation .group-tauros {
    width: 80%;
    height: auto;
    overflow: hidden;
    padding: 2%
}

.home-page main .presentation .group-tauros img {
    width: 100%;
    height: 100%;
}

.home-page main .presentation .quote {
    text-align: justify;
    text-justify: inter-word;
    font-size: 18px;
}

.home-page main .presentation .quote .q1 {
    text-align: center;
    font-size: 22px;
    padding: 5% 0;
}

.home-page main .presentation .quote .q2 {
    padding: 2% 0;
}


.home-page main .presentation .quote h2 {
    text-align: left;
    padding: 2%;
}

.home-page main .presentation .quote ul {
    padding-left: 5%;
}


/* carousel */

.carousel {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.home-page main .description {
    position: relative;
    margin: 30px 10px;
    width: 80%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
}

/* Font */

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width:600px) {
    .home-page main .slogan {
        font-size: 25px;
    }
    .home-page main .slogan h2 {
        width:auto;
    }
}