.album-page 
{
    height: auto;
    max-height: 100vh;
}

.album-page main {
    overflow: hidden;
    padding: 20px;

    display: block;
    width: auto;
    height: 100%;
    min-height: 100vh;


    background-color: #212121;
}

.album-page main .grid {
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.album-page main .grid .album {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin: 0px 80px;
    padding-top: 5%;
    height: auto;
    width: auto;

    background-color: #212121;
}

.album-page main .grid .album .logo {
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.album .logo:hover {
    transform: scale(1.2);
}

.album-page main .grid .album .text {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    flex-wrap: nowrap;

    background-color: #212121;
    color : white;
    font-size: 24px;
    font-weight: 600;
}

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width: 600px) { /* Taille standard pour iPhone */
    .album-page main {
        padding: 10% 0;
    }
    
    .album-page main .grid .album {
        padding: 0;
        margin: 10% 0;
        width: 80%;
        overflow: hidden;
    }
    
    .album-page main .grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .album-page main .grid .album .a {
        width: 100%;
    }
    .album-page main .grid .album .text {
        margin: 0;
        text-align: center;
    }


}