/* entete */

.topbar {
    font-size: 22px;
    width: 100%;
}

.topbar header {
    position: relative;
    padding : 10px 0px;
    height : auto;

    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to bottom, #a3a300, #131313);
}

.topbar header .header-link {
    position: absolute;
    right: 0;    

    font-size: 18px;
}

.topbar header .header-logo {
    width: 150px;
    height: auto;
}

.topbar header .header-logo a .logo {
    width: 100%;
}

.topbar header h2 a{
    color: white;
    text-decoration: none;
    padding: 0% 50px;
    transition: transform 0.3s ease-in-out;
    display: inline-block;
    align-self: flex-end;
}

.topbar header h2 a:hover{
    color: yellow;
    transform: scale(1.3);
}

.topbar header .toplogo {
    padding: 0% 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background: url('./img/Logo_officiel.png') center center no-repeat;
    background-size: contain;
    height: 100px;  
    width: 100px;
}

/* ---------- navbar ---------- */

.topbar .navbar {
    padding : 10px 0px;
    top : 0;
    height : auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: #131313;
    font-size: 1.5vw;
}

.topbar .navbar a {
    position: relative;
    padding: 5px 2.5%;
    text-decoration: none;
    color: white;
}

.topbar .navbar a:hover {
    color: grey;
}

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width: 600px) { /* Taille standard pour iPhone */
    .topbar {
        width : 100%;
    }

    .topbar .navbar {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        transition: height 0.4s;
        height : auto;
        font-size: 22px;
    }

    .topbar .navbar.closed {
        font-size: 0;
        height: 0;
    }

    .topbar .navbar.closed .link {
        padding: 0;
        opacity: 0;
    }

    .topbar .nav-button-container {
        background-color: #131313;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
    }

    .nav-button {
        display: flex;
        padding: 2%;
        background: #131313;
        border: 2px solid yellow;
    }

    .topbar .navbar .link {
        padding: 15px 20px;
        transition: opacity 1s;
        opacity: 1;
    }
   
}