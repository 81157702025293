.partenaire-page {
    display: block;
    width: auto;
    height: auto;
    background-color: #212121;
}

/* --------------- main --------------- */

.partenaire-page main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7% 0;
}

.partenaire-page main .partenaires {
    max-width: 75%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    object-fit: contain;
}

.partenaire-page main .partenaires .visu {
    width: 100%;
}

.partenaire-page main .partenaires .visu img {
    width: 100%;
    height: auto;
    object-fit: contain;
}