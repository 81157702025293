/* Modal */
.modal {
    display: flex;
    height: auto;
    width : auto;
    padding: 5%;
    position: fixed;
    flex-direction: column;
    z-index: 1000;
    top:50%;
    left: 30%;
}

.modal-content {
    background-color: #C0C0C0;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
}


.close {
    font-size: 30px;
    cursor: pointer;
}

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width: 600px) {
    .modal {
        justify-self: center;
        width: 80%;
        padding: 0;
        overflow: hidden;
        left:10%
    }

    .modal-content {
        width: auto;
    }
}
