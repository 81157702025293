.member-page {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 100vh;
    background-color: #212121;
}

.member-page .barside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: auto;
    background-color: #f5f5f5;
    transition: width 0.5s;
    width: 250px; 
}

.barside.closed {
    width: 0; 
    font-size: 0;
}

.barside.closed .logo {
    width: 0;
    height: 0;
}

.barside .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 10%;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    opacity: 1;
    transition: opacity 0.2s;
}

.toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 10%;
    color: white; 
    border: 2px solid yellow; 
    background: transparent; 
    transition: background 0.3s, color 0.3s;
}

.toggle-button:hover {
color: black; 
background: yellow; 
}

.barside .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20%;
    width: auto;
    height: 90%;
    background-color: #f5f5f5;
    opacity: 1;
    transition: opacity 0.2s;
}

.barside .links .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}