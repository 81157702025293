.trustedpeople-page {
    display: block;
    width: auto;
    height: auto;
    /* min-height: 100vh; */
    overflow: hidden;
}
/* ---------------------- content ---------------------- */

.trustedpeople-page main .members {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #131313;
    height: auto;
    width: 100%;
    padding: 50px;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.trustedpeople-page main .members .member {
    width: auto;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trustedpeople-page main .members img {
    width: 20vw;
    height: auto;
}

.trustedpeople-page main .members .member .media {
    display: flex;
    flex-direction: row;
}

.trustedpeople-page main .members .member .media a {
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
    padding: 10%;
}
.trustedpeople-page main .members .member .media a:hover {
    color: yellow;
}

.trustedpeople-page main .members .member .quote {
    color: white;
    font-style: italic;
    width: 80%;
    text-align: center;
}

.trustedpeople-page main .color-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    background-color: #131313;
}

.trustedpeople-page main .color-description .row-color .column-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    padding: 20px 5%
}

.trustedpeople-page main .color-description .color-title {
    font-size: 28px;
    color: #FFF;
}

.trustedpeople-page main .color-description .row-color {
    width: 100%;
    display : flex;
    flex-direction: row;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background-color: #131313;
}

.trustedpeople-page main .color-description .row-color .column-color .color {
    padding: 5% 0;
    height: auto;
    width: 100%;
    font-size: 2vw;
}

/* ---------------------------------------------------- */
/* ===================== Iphones ====================== */
/* ---------------------------------------------------- */

@media only screen and (max-width:600px) {
    .trustedpeople-page {
        width: auto;
        min-width: none;
        height: auto;
        min-height: none;
    }

    .trustedpeople-page main .members {
        padding: 10% 0 0 0;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .trustedpeople-page main .members .member {
        width : 80%;
        height: auto;
        padding-bottom: 10%;
    }

    .trustedpeople-page main .members img {
        width: 60%;
        height: auto;
    }

    .trustedpeople-page main .members .member .media {
        width : auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .trustedpeople-page main .members .member .media a {
        padding: 0;
        width: auto;
        height: auto;
        font-size: 1.5rem;
        text-decoration: none;
        color: white;
    }

    .trustedpeople-page main .color-title {
        color: white;
        font-size : 34px;
        border-top: #FFF;
        border-width: 5px;
    }

    .trustedpeople-page .row-color {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .trustedpeople-page .row-color .color-description {
        display : flex;
        width: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #131313;
    }
    
    .trustedpeople-page .row-color .color-description .color {
        color: #FFF;
        padding: 15px 0px;
        font-size: 20px;
    }

    /*TODO: Refaire la sémentique de la version iphone pour le code couleur*/
    
}