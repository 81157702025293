.shop-page {
    display: block;
    width: auto;
    height: 100vh;
}

.wip {
    height: 100%;
    width: 100%;
}

/* ---------------------- main ---------------------- */

.shop-page main {
    display: block;
    width: auto;
    height: 100vh;
}

.shop-page main header {
    padding : 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #fff;
    font-size: 30px;
}

.shop-page main .product-lign {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.shop-page main .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    padding: 10px;
    /* Ajoutez plus de styles pour chaque produit ici */
}

.shop-page main .item .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0px;
}

.shop-page main .item .description {
    font-size: 1rem;
    margin: 10px 0px;
}
    
.shop-page main .item .price {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0px;
}
  