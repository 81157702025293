* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

/* @media only screen and (min-width: 600px) {
  * {
    font-family: 'Courier New', Courier, monospace;
  }
} */
  
/* @media only screen and (max-width: 600px) {
  * {
    overflow-x: hidden;
  }
} */

/* --------- Font Family ---------- */

@font-face {
  font-family: 'AllStar';
  src: url('./data/font/AllStar.ttf') format('truetype');

  font-family: 'LemonMilk';
  src: url('./data/font/lemon_milk/LEMONMILK-Regular.otf') format('opentype');

  font-family: 'LemonMilk';
  src: url('./data/font/lemon_milk/LEMONMILK-Medium.otf') format('opentype');
  font-weight: bold;

  font-family: 'GTA';
  src: url('./data/font/Pricedown.otf') format('opentype');
}

/* --------- Database ---------*/

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#allstar {
  font-family: 'AllStar';
}



/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  width: auto;
  height: auto;
} */