.map-container {
    width: 100%;
    height: 100%;
}

#map {
    height: 100%;
    width: 100%;
}
  
@media only screen and (max-width: 600px) {
    .map-container {
        min-height: 100px;
    }

    #map {
        min-height: 100px;
    }
}
