.welcome {
    position: relative;
    height: 100vh;
    background-color: rgb(13, 13, 13);
    overflow: hidden;
}

.welcome::before {
    content: "";
    background: radial-gradient(circle at center, rgba(0,0,0,0) 0%, rgb(25, 25, 25) 100%);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}  

.welcome::after {
    content: "";
    background: url('./WelcomeTauros.png') center center no-repeat;
    background-size: auto 70%;
    background-position: top;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;   
}

.welcome-button {
    position: absolute;
    bottom: 17.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 20px;
    z-index: 2;

    border: 2px solid yellow; /* Pour un contour jaune */
    color: yellow; /* Pour un texte jaune */
    background: transparent; /* Pour un fond transparent */
    transition: background 0.3s, color 0.3s; /* Pour une transition douce */
}

.welcome-button:hover {
    color: black; /* Pour un texte noir */
    background: yellow; /* Pour un fond jaune */
}

@media only screen and (max-width: 600px) {
    .welcome {
        height: 100vh;
    }

    .welcome::after {
        background-position: center;
        background-size: contain;
    }

    .welcome-button {
        bottom: 10%;
        font-size: 16px;  /* Réduction de la taille de la police */
        padding: 8px 16px;  /* Réduction du padding */
    }
}