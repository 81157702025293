.carousel-container {
    position: relative;
}
  
.carousel-images {
    position: relative;
    width: 150px; /* ajuste selon tes besoins */
    height: 150px; /* ajuste selon tes besoins */
}
  
.carousel-images img {
    width: 100%;
    height: 100%;
}
  
.carousel-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
  